.login-container{
    margin: auto;
    width: 30em;

    .login-link{
        margin-top: 5px;
        margin-right: 20px;
    }

    .login-button {
        margin-top: 10px;
    }
}