.ui.container{
    .ui.grid {
        .row {
            &.edit-provider-form {
                .ui.form{
                    width: 60em;
                    .checkbox {
                        label {
                            color: rgba(0,0,0,.87);
                            font-size: .92857143em;
                            font-weight: 700;
                            text-transform: none;
                        }
                    }
                }  
            }
        }
    }
}