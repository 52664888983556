body {
    background-color: rgb(244, 244, 244) !important;
}

.ui.inverted.top.fixed.menu {
    background: #1b1c1d;
}

/*home page styles*/

.masthead {
    display: flex;
    align-items: center;
    height: 100vh;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
    margin-left: 0.5em;
}

.masthead h1.ui.header {
    font-size: 4em;
    font-weight: normal;
}

.masthead h2 {
    font-size: 1.7em;
    font-weight: normal;
}

/*end home page styles*/

.ui.grid {
    .row {
        .sixteen.wide.column {
            padding: 0;
            margin: 0;
        }
    }
}

.app-container.ui.container {
    width: 100%;
    min-height: 100vh;
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.ml-10 {
    margin-left: 10px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.pointer{
    cursor: pointer;
}

.break-word{
    word-break: break-word;
}

.break-all{
    word-break: break-all;
}

/* Landing */
#landing{
    .cards{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-gap: 1.5rem 1rem;
        margin: 0 2px;
    }
    .card{
        width: auto;
        margin: 0;
    }
} 